<script lang="ts">
    import { inertia } from '@inertiajs/svelte';

    let navItems = [
        {
            label: 'Balance',
            value: 1,
            href: '/wallet/balance',
        },
        {
            label: 'Transactions',
            value: 2,
            href: '/wallet',
        }
    ];
    /*
            {
            label: 'Items',
            value: 3,
            href: '/wallet/items',
        },

     */
</script>

<div>
    <nav
        class="-mb-px flex space-x-8 border-b border-solid border-neutral-200 dark:border-neutral-700"
        aria-label="Tabs"
    >
        {#each navItems as item}
            <a
                use:inertia
                href="{item.href}"
                id="pills-home-tab"
                class="{item.href === window.location.pathname
                    ? 'top-1 cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-black px-3 py-2 text-base font-semibold text-black dark:border-white dark:text-white'
                    : 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-transparent px-3 py-2 text-base font-semibold text-gray-500 '}"
            >
                {item.label}
            </a>
        {/each}
    </nav>
</div>

<style>
    #pills-home-tab {
        margin: 0 0 -2px;
    }
</style>
